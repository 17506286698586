.upload-memo {
    overflow: auto;
    resize: vertical;
    width: 100%;

    padding: 0.56rem 1rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #a9acb4;
    border-radius: 0.25rem;
    margin: 8px 0;
}

.drag-drop-wrapper {
    width: 100%;
    border: 2.5px dashed #ccc;
    border-radius: 16px;
    min-height: 120px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}