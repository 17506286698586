.form-control {
  width: 100%;
  height: calc(1.5em + 1.12rem + 2px);
  padding: 0.56rem 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: #eaeaf9;
  border-radius: 0.25rem;
  border: 1px solid #e5e9f2;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}

.form-control:focus {
  outline: 1px solid #e5e9f2;
}

.sign-block {
  margin-inline: 150px;
  text-align: right;
}
