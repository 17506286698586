.table-header-cell-rs {
    font-size: 15px;
    font-weight: 400;
    color: #000;
}

.table-row-rs {
    cursor: pointer;
}

.table-tree-head {
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
    color: #030192;
}