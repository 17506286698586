.sideber-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #ffffff;

    width: 80px;
    overflow: hidden;

    z-index: 1200 !important;
    /*Default MUI Drawer z-index*/

    transition: width, 0.35s;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.sideber-wrapper:hover {
    width: 250px;
    transition: width, 0.4s;
}

